import { BrandConfigType } from './config';

// auto copied before start/build from ./src/whitelabels
import { wlConfig } from './wlConfig';

// How to get values in bash
// echo -n 'cardaq' | md5sum | awk '{print $1}'

export const DECTA = '54cfc9272d9aeb761f475b688a519197';
export const CARDAQ = '463d775af7b29e835b1cdce03b96f557';
export const NOVOWORLD = 'd2c8c8b94f635c97ce86ad14a645c589';
export const LIPAPAP = '50a832aae50dd401d09cdb7e03b03475';
export const VECTOR = '6ba8844da718b4a65f60dbfd0d92d6ef';
export const BREEZE = 'e97abd871ea2793ffa98f430ed268c35';
export const ARBIPAY = '34a30ed12135a047df9a026c4d8d03b7';
export const XCHECKOUTS = '5ebdf27859a481a4c5695c7801af4989';
export const CARDIFY = 'e6aba9f99b6b5d5b6182fab0ea7a2f5d';
export const PAYONET = 'd1bf1a2215a5118ce141c8fbb4e3eee7';
export const TESLAPAY = 'ad5d10ded2153d66f8f5d5eba0b5a071';

export const AMEX_ENABLED = false;

export const BRAND_URL = (wlConfig as unknown as BrandConfigType).url;
